export const GET_CATEGORYS_STATED = "GET_CATEGORYS_STATED";
export const GET_CATEGORYS = "GET_CATEGORYS";
export const GET_CATEGORYS_ENDED = "GET_CATEGORYS_ENDED";
export const ADD_CATEGORY_STATED = "ADD_CATEGORY_STARTED";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_ENDED = "ADD_CATEGORY_ENDED";
export const EDIT_CATEGORY_STATED = "EDIT_CATEGORY_STATED";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const EDIT_CATEGORY_ENDED = "EDIT_CATEGORY_ENDED";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_STATED = "GET_CATEGORY_STATED";
export const GET_CATEGORY_ENDED = "GET_CATEGORY_ENDED";
export const RESET_CATEGORY = "RESET_CATEGORY";
export const ERROR_CATEGORY = "ERROR_CATEGORY";
export const GET_ALL_CATEGORYS_STATED = "GET_ALL_CATEGORYS_STATED";
export const GET_ALL_CATEGORYS = "GET_ALL_CATEGORYS";
export const GET_ALL_CATEGORYS_ENDED = "GET_ALL_CATEGORYS_ENDED";
