export const GET_PRODUCTCATEGORYS_STATED = "GET_PRODUCTCATEGORYS_STATED";
export const GET_PRODUCTCATEGORYS = "GET_PRODUCTCATEGORYS";
export const GET_PRODUCTCATEGORYS_ENDED = "GET_PRODUCTCATEGORYS_ENDED";
export const ADD_PRODUCTCATEGORY_STATED = "ADD_PRODUCTCATEGORY_STARTED";
export const ADD_PRODUCTCATEGORY = "ADD_PRODUCTCATEGORY";
export const ADD_PRODUCTCATEGORY_ENDED = "ADD_PRODUCTCATEGORY_ENDED";
export const EDIT_PRODUCTCATEGORY_STATED = "EDIT_PRODUCTCATEGORY_STATED";
export const EDIT_PRODUCTCATEGORY = "EDIT_PRODUCTCATEGORY";
export const EDIT_PRODUCTCATEGORY_ENDED = "EDIT_PRODUCTCATEGORY_ENDED";
export const GET_PRODUCTCATEGORY = "GET_PRODUCTCATEGORY";
export const GET_PRODUCTCATEGORY_STATED = "GET_PRODUCTCATEGORY_STATED";
export const GET_PRODUCTCATEGORY_ENDED = "GET_PRODUCTCATEGORY_ENDED";
export const RESET_PRODUCTCATEGORY = "RESET_PRODUCTCATEGORY";
export const ERROR_PRODUCTCATEGORY = "ERROR_PRODUCTCATEGORY";
export const GET_ALL_PRODUCTCATEGORYS_STATED = "GET_ALL_PRODUCTCATEGORYS_STATED";
export const GET_ALL_PRODUCTCATEGORYS = "GET_ALL_PRODUCTCATEGORYS";
export const GET_ALL_PRODUCTCATEGORYS_ENDED = "GET_ALL_PRODUCTCATEGORYS_ENDED";
