export const GET_CONTACTS_STATED = "GET_CONTACTS_STATED";
export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_ENDED = "GET_CONTACTS_ENDED";
export const ADD_CONTACT_STATED = "ADD_CONTACT_STARTED";
export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_CONTACT_ENDED = "ADD_CONTACT_ENDED";
export const EDIT_CONTACT_STATED = "EDIT_CONTACT_STATED";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const EDIT_CONTACT_ENDED = "EDIT_CONTACT_ENDED";
export const GET_CONTACT = "GET_CONTACT";
export const GET_CONTACT_STATED = "GET_CONTACT_STATED";
export const GET_CONTACT_ENDED = "GET_CONTACT_ENDED";
export const RESET_CONTACT = "RESET_CONTACT";
export const ERROR_CONTACT = "ERROR_CONTACT";
export const GET_ALL_CONTACTS_STATED = "GET_ALL_CONTACTS_STATED";
export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
export const GET_ALL_CONTACTS_ENDED = "GET_ALL_CONTACTS_ENDED";
